import "../styles/resume.css";
import BackgroundPicture from "../assets/background.jpg";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

const Contact = (props) => {
    const formRef = useRef();
    const sectionRef = useRef(null);
    const {scrollYProgress} = useScroll({
        target: sectionRef,
    });
    const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);
    const scroll = useTransform(scrollYProgress, [0, .75], ["125%", "0%"]);

    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button

        emailjs
            .sendForm('service_18x3viv', 'template_8knkc6e', formRef.current, {
                publicKey: 'B9zYHnwEFXCgrZ7qT',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSubmitted(true);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    return (
        <section 
            ref={sectionRef}
            className="relative w-full h-[125vh] bg-color1">
            <div 
                className="sticky flex justify-center items-center lg:h-screen p-3 bottom-0 top-0 h-screen w-full overflow-hidden"
                id="contact"
            >
                <motion.div 
                className="w-[85vw] h-min lg:w-[50%] lg:h-[75%] flex flex-col-reverse lg:flex-row gap-2 bg-blue3/10 rounded-3xl backdrop-blur-md shadow-2xl"
                style={{
                    transition: "all .1s ease-out",
                    opacity: opacity,
                    translateX: scroll,
                }}
                >
                    {submitted ? (
                        <div className="size-full p-8 rounded-2xl shadow-md text-white flex flex-col flex-1 justify-center items-center">
                            <h2 className="text-2xl font-bold mb-6 text-center">Thank You!</h2>
                            <p className="text-center">Your message has been sent successfully. I will be in contact with you soon.</p>
                            <i className="bi bi-check-circle-fill text-7xl text-blue4"></i>
                        </div>
                    ) : (
                        <form ref={formRef} onSubmit={handleSubmit} className="size-full p-8 rounded-2xl shadow-md text-white flex flex-col flex-1 justify-center items-center">
                            <h2 className="text-4xl font-bold mb-6 text-center">Contact Me</h2>
                            <div className="mb-4">
                                <label htmlFor="name" className="block font-semibold mb-2">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="w-[100%] px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink1 bg-transparent"
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="email" className="block font-semibold mb-2">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink1 bg-transparent"
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="message" className="block font-semibold mb-2">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink1 bg-transparent resize"
                                    placeholder="Enter your message"
                                    rows="5"
                                    required
                                ></textarea>
                            </div>

                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    className="px-6 py-2 bg-blue4 text-white font-semibold rounded-md hover:bg-pink1 focus:outline-none focus:ring-2 focus:ring-pink1 focus:ring-opacity-50 transition-colors hover:ease-in-out "
                                    disabled={isSubmitting} // Disable the button when submitting
                                >
                                    {isSubmitting ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </form>
                    )}
                    <div className="flex flex-1 w-full justify-center items-center flex-col text-white">
                        <i className="bi bi-envelope text-blue4 text-[15vw]"></i>
                    </div>
                </motion.div>
            </div>
        </section>
    )
}

export default Contact;
