import { useState, useEffect } from "react";
import { motion, useScroll } from "framer-motion";
import "../styles/header.css";

const Header = (props) => {
    const { scrollYProgress } = useScroll();
    const [color, setColor] = useState(false);
    const changeColor = () => {
        if(window.scrollY >= 300) {
            setColor(true);
        }
        else {
            setColor(false);
        }
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen((open) => !open);
    };

    window.addEventListener('scroll', changeColor)

    return (
        <header id="home">
            <nav id="nav-wrap" className={`nav-wrap z-50 ${color ? "nav-bg-color" : ""} ${isOpen ? "is-open" : ""} scroll-smooth`}>
                <motion.div
                id="progress-bar"
                style={{ scaleX: scrollYProgress }}
                />
                <ul id="nav" className="nav">
                    <div className={`nav-items ${isOpen ? "is-open" : ""}`}>
                        <li className="current">
                            <a href="#hero" onClick={toggleMenu}>
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#resume" onClick={toggleMenu}>
                                About
                            </a>
                        </li>
                        <li>
                            <a href="#contact" onClick={toggleMenu}>
                                Contact
                            </a>
                        </li>
                    </div>
                    <i className={`nav-menubar ${isOpen ? "bi-x" : "bi-list"} p-1 ${isOpen ? "is-open" : ""}`} onClick={toggleMenu}/>
                </ul>
            </nav>
        </header>
    );
}

export default Header;