import { motion, useScroll, useTransform, useAnimation } from "framer-motion";
import { useRef } from "react";
import BackgroundPicture from "../assets/BG-Full.jpg";
import SoloBGPicture from "../assets/BG-Solo.png";

const SECTION_HEIGHT = 1500;
const heroText = "Welcome";

const Hero = () => {
  const heroRef = useRef(null);
  const {scrollY} = useScroll();
  const {scrollYProgress} = useScroll({
    target: heroRef,
    offset: ["start start", "end start"],
  });
  // const opacity = useTransform(scrollY, [SECTION_HEIGHT, SECTION_HEIGHT * 1.5], [1, 0]);
  // const arrowOpacity = useTransform(scrollY, [0, SECTION_HEIGHT * .5], [1, 0]);
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
  const soloBGY = useTransform(scrollYProgress, [0, 1], ["0%", "25%"]);
  const textY = useTransform(scrollYProgress, [0, 1], ["0%", "150%"]);

  return (
    <section id="hero" ref={heroRef} className="relative w-full h-screen text-white flex justify-center overflow-hidden">
      <motion.div style={{y: textY, transition: "ease-in-out"}} className="font-semibold text-8xl md:text-9xl relative z-10 top-[10vh]">
        {heroText.split("").map((letter, index) => {
          return <motion.span key={index} initial={{y: "-100%", opacity: 0}} animate={{y: "0%", opacity: 1}} transition={{delay: 0.2 * index, duration: .15}}>
                {letter}
              </motion.span>;
        })} 
      </motion.div>
      <motion.div style={{
        backgroundImage: `url(${BackgroundPicture})`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        y: backgroundY,
      }}
      className="absolute inset-0 z-0"
      />
      <motion.div style={{
        backgroundImage: `url(${SoloBGPicture})`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        y: soloBGY,
      }}
      className="absolute inset-0 z-20"
      />
      <div className="absolute bottom-0 left-0 right-0 h-[30vh] bg-gradient-to-b from-color1/0 to-color1 w-full z-[30]"/>
    </section>
  );
};

export default Hero;