import React, { useRef, useEffect, useState } from 'react';
import video from '../assets/video.mp4';
import nohud from '../assets/nohud2.mp4';
import panel1 from '../assets/panel1.png';
import panel2 from '../assets/panel2.png';
import { SocialButtons } from './Resume';

export const Projects = () => {
  const [isHovered, setIsHovered] = useState(false);
  const hudVideoRef = useRef(null);
  const noHudVideoRef = useRef(null);

  // Sync both videos to start simultaneously
  useEffect(() => {
    if (hudVideoRef.current && noHudVideoRef.current) {
      hudVideoRef.current.currentTime = 0;
      noHudVideoRef.current.currentTime = 0;
      hudVideoRef.current.play();
      noHudVideoRef.current.play();
    }
  }, []);

  // console.log(hudVideoRef.current.currentTime)

  return (
    <section id="projects" className="relative grid grid-cols-1 lg:grid-cols-2 gap-4 p-2">
      {/* Project Info */}
      <div className="flex flex-col justify-center items-center px-10 py-8 lg:py-1 col-span-1">
        <h1 className="text-8xl text-center text-white font-bold mb-8">Projects</h1>
        <div className="flex flex-col gap-4">
          <div className="text-left">
            <h3 className="text-4xl text-green-400 mb-1 font-semibold">OpenHUD</h3>
            <h6 className="text-lg text-gray-300 mb-2">
              Open Source HUD/Admin Panel for Counter-Strike 2.
            </h6>
            <p className="text-gray-400">
              It uses the <a href='https://developer.valvesoftware.com/wiki/Counter-Strike:_Global_Offensive_Game_State_Integration' target='_blank' className='text-green-400 hover:text-green-500' rel="noreferrer">Game State Integration</a> to send HTTP requests to a
              back-end server that connects to the React front-end, which
              changes based on events that happen in-game, ie. Round-State, Player-State, etc.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-2xl text-white font-bold">Technologies Used:</h5>
            <ul className="grid grid-cols-2 gap-4">
              <li className="text-gray-400">React</li>
              <li className="text-gray-400">Typescript</li>
              <li className="text-gray-400">Node.js</li>
              <li className="text-gray-400">Express</li>
              <li className="text-gray-400">Socket.io</li>
              <li className="text-gray-400">SQLite3</li>
              <li className="text-gray-400">Material UI</li>
              <li className="text-gray-400">Tailwind CSS</li>
              <li className="text-gray-400">Sass (optional)</li>
            </ul>
          </div>
        </div>
        <SocialButtons name={'github'} link={'https://github.com/JohnTimmermann/OpenHud'} />
      </div>

      {/* Video Section */}
      <div className="relative h-[50vh] lg:h-screen flex flex-col justify-center items-center">
        <h1 
        className={"text-stone-100 text-6xl capitalize z-20" + (isHovered ? " opacity-0" : " opacity-100")}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onTouchStart={() => setIsHovered(true)}
          onTouchEnd={() => setIsHovered(false)}
        >Hover to hide HUD</h1>
        <div className='flex justify-center items-center size-min'>
          {/* HUD Video */}
        <video
          ref={hudVideoRef}
          className={"object-contain object-center block absolute w-full z-10" + (isHovered ? " opacity-0" : " opacity-100") }
          autoPlay
          loop
          muted
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onTouchStart={() => setIsHovered(true)}
          onTouchEnd={() => setIsHovered(false)}
        >
          <source src={video} type="video/mp4" />
        </video>
        
        {/* No HUD Video */}
        <video
          ref={noHudVideoRef}
          className={"object-contain object-center absolute w-full" + (isHovered ? " opacity-100" : " opacity-0") }
          autoPlay
          loop
          muted
        >
          <source src={nohud} type="video/mp4" />
        </video>
        </div>
      </div>

      {/* Images */}
      <img src={panel1} alt="panel1" className="w-full h-full object-cover object-center" />
      <img src={panel2} alt="panel2" className="w-full h-full object-cover object-center" />
    </section>
  );
};
